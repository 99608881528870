'use strict';

var List = require("bs-platform/lib/js/list.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/src/ReasonReact.js");

function str(prim) {
  return prim;
}

function show(x) {
  return "" + (String(x) + "");
}

function tap(x) {
  console.log(show(x));
  return x;
}

function tap2(x, y) {
  console.log(x, show(y));
  return y;
}

function ignoreRender() {
  return null;
}

function ignore() {
  return /* () */0;
}

var component = ReasonReact.statelessComponent("Button");

function make(label, onClick, $staropt$star, _) {
  var disabled = $staropt$star !== undefined ? $staropt$star : false;
  return /* record */Block.record([
            "debugName",
            "reactClassInternal",
            "handedOffState",
            "willReceiveProps",
            "didMount",
            "didUpdate",
            "willUnmount",
            "willUpdate",
            "shouldUpdate",
            "render",
            "initialState",
            "retainedProps",
            "reducer",
            "jsElementWrapped"
          ], [
            component[/* debugName */0],
            component[/* reactClassInternal */1],
            component[/* handedOffState */2],
            component[/* willReceiveProps */3],
            component[/* didMount */4],
            component[/* didUpdate */5],
            component[/* willUnmount */6],
            component[/* willUpdate */7],
            component[/* shouldUpdate */8],
            (function () {
                return React.createElement("button", {
                            disabled: disabled,
                            onClick: onClick
                          }, label);
              }),
            component[/* initialState */10],
            component[/* retainedProps */11],
            component[/* reducer */12],
            component[/* jsElementWrapped */13]
          ]);
}

var Button = /* module */Block.localModule([
    "component",
    "make"
  ], [
    component,
    make
  ]);

var component$1 = ReasonReact.statelessComponent("Text");

function make$1(label, $staropt$star, _) {
  var style = $staropt$star !== undefined ? $staropt$star : "";
  return /* record */Block.record([
            "debugName",
            "reactClassInternal",
            "handedOffState",
            "willReceiveProps",
            "didMount",
            "didUpdate",
            "willUnmount",
            "willUpdate",
            "shouldUpdate",
            "render",
            "initialState",
            "retainedProps",
            "reducer",
            "jsElementWrapped"
          ], [
            component$1[/* debugName */0],
            component$1[/* reactClassInternal */1],
            component$1[/* handedOffState */2],
            component$1[/* willReceiveProps */3],
            component$1[/* didMount */4],
            component$1[/* didUpdate */5],
            component$1[/* willUnmount */6],
            component$1[/* willUpdate */7],
            component$1[/* shouldUpdate */8],
            (function () {
                return React.createElement("div", {
                            className: style
                          }, label);
              }),
            component$1[/* initialState */10],
            component$1[/* retainedProps */11],
            component$1[/* reducer */12],
            component$1[/* jsElementWrapped */13]
          ]);
}

var Text = /* module */Block.localModule([
    "component",
    "make"
  ], [
    component$1,
    make$1
  ]);

function dropWhile(_list, pred) {
  while(true) {
    var list = _list;
    if (list) {
      var match = !Curry._1(pred, list[0]);
      if (match) {
        return list;
      } else {
        _list = list[1];
        continue ;
      }
    } else {
      return /* [] */0;
    }
  };
}

function a(s) {
  return React.createElement("div", undefined, s);
}

var a1 = React.createElement("div", undefined, React.createElement("button", undefined));

function b(s1, s2) {
  return React.createElement("div", undefined, a(s1), a(s2));
}

function c(s1, s2) {
  return React.createElement("div", undefined, a(s1), a(s2));
}

function d(l) {
  return $$Array.of_list(List.map((function (param) {
                    return b(param[0], param[1]);
                  }), l));
}

exports.str = str;
exports.show = show;
exports.tap = tap;
exports.tap2 = tap2;
exports.ignoreRender = ignoreRender;
exports.ignore = ignore;
exports.Button = Button;
exports.Text = Text;
exports.dropWhile = dropWhile;
exports.a = a;
exports.a1 = a1;
exports.b = b;
exports.c = c;
exports.d = d;
/* component Not a pure module */
