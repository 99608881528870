'use strict';

var List = require("bs-platform/lib/js/list.js");
var Util = require("./Util.bs.js");
var $$Array = require("bs-platform/lib/js/array.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Format = require("bs-platform/lib/js/format.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Debug = require("bs-platform/lib/js/belt_Debug.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Player$ReReactPlayer = require("re-react-player/src/Player.bs.js");

Belt_Debug.setupChromeDebugger(/* () */0);

function renderSongHeader(song, onSelect, style) {
  return React.createElement("div", {
              className: style
            }, React.createElement("div", {
                  className: "song-title clickable",
                  onClick: (function () {
                      return Curry._1(onSelect, song);
                    })
                }, Util.str(song[/* title */1])), React.createElement("div", {
                  className: "song-artist"
                }, Util.str(song[/* artist */2])), React.createElement("div", {
                  className: "comment-count"
                }, Util.str("Comments: " + String(List.length(song[/* feedback */4])))));
}

function renderCommentsRoll(songInProgress, style) {
  var withinDelta = function (a, b, delta) {
    return Math.abs(a - b) <= delta;
  };
  return React.createElement("div", {
              className: style
            }, React.createElement("div", {
                  className: "comment"
                }, ReasonReact.element(undefined, undefined, Util.Text[/* make */1]("Comment", "bold", /* array */[])), ReasonReact.element(undefined, undefined, Util.Text[/* make */1]("Loc", "bold", /* array */[]))), $$Array.of_list(List.map((function (c) {
                        var match = withinDelta(c[/* location */0], songInProgress[/* position */1], 2.0);
                        return React.createElement("div", {
                                    className: "comment"
                                  }, ReasonReact.element(undefined, undefined, Util.Text[/* make */1](c[/* content */1], match ? "comment-text highlight" : "comment-text", /* array */[])), ReasonReact.element(undefined, undefined, Util.Text[/* make */1](Curry._1(Format.sprintf(/* Format */Block.simpleVariant("Format", [
                                                      /* Float */Block.variant("Float", 8, [
                                                          /* Float_f */0,
                                                          /* Lit_padding */Block.variant("Lit_padding", 0, [
                                                              /* Right */1,
                                                              0
                                                            ]),
                                                          /* Lit_precision */Block.simpleVariant("Lit_precision", [1]),
                                                          /* End_of_format */0
                                                        ]),
                                                      "%0.1f"
                                                    ])), c[/* location */0]), "comment-position", /* array */[])));
                      }), songInProgress[/* song */0][/* feedback */4])));
}

function renderPlayerOnCurrentSong(currentlyPlaying, send, style) {
  var t = currentlyPlaying[/* enteredText */2];
  return React.createElement("div", {
              className: style
            }, ReasonReact.element(undefined, undefined, Util.Text[/* make */1]("If a SoundCloud Ad blocks Play/Pause, click 'X' to close the Ad.", "italic", /* array */[])), ReasonReact.element(undefined, undefined, Player$ReReactPlayer.make(currentlyPlaying[/* song */0][/* url */3], undefined, 100, undefined, undefined, undefined, (function (progress) {
                        return Curry._1(send, /* UpdatePosition */Block.variant("UpdatePosition", 1, [progress]));
                      }), undefined, undefined, /* array */[])), ReasonReact.element(undefined, undefined, Util.Text[/* make */1]("Pause / Add Comment @ Location: " + Curry._1(Format.sprintf(/* Format */Block.simpleVariant("Format", [
                                /* Float */Block.variant("Float", 8, [
                                    /* Float_f */0,
                                    /* Lit_padding */Block.variant("Lit_padding", 0, [
                                        /* Right */1,
                                        0
                                      ]),
                                    /* Lit_precision */Block.simpleVariant("Lit_precision", [1]),
                                    /* End_of_format */0
                                  ]),
                                "%0.1f"
                              ])), currentlyPlaying[/* position */1]), "bold", /* array */[])), React.createElement("textarea", {
                  className: "comment-entry",
                  cols: 60,
                  rows: 5,
                  value: t,
                  onChange: (function (ev) {
                      return Curry._1(send, /* CommentTextChange */Block.variant("CommentTextChange", 2, [ev.target.value]));
                    })
                }), ReasonReact.element(undefined, undefined, Util.Button[/* make */1]("Submit", (function () {
                        return Curry._1(send, /* LeaveComment */0);
                      }), t === "", /* array */[])));
}

function renderSong(song, currentlyPlaying, send) {
  var header = renderSongHeader(song, (function (s) {
          return Curry._1(send, /* Select */Block.variant("Select", 0, [s]));
        }), "song-header");
  var match;
  if (currentlyPlaying !== undefined) {
    var inProgress = currentlyPlaying;
    match = inProgress[/* song */0][/* id */0] === song[/* id */0] ? /* tuple */[
        renderPlayerOnCurrentSong(inProgress, send, "song-player"),
        renderCommentsRoll(inProgress, "song-comments")
      ] : /* tuple */[
        null,
        null
      ];
  } else {
    match = /* tuple */[
      null,
      null
    ];
  }
  return React.createElement("div", {
              className: "song-grid"
            }, header, match[0], match[1]);
}

function renderSongList(songList, currentlyPlaying, send) {
  return $$Array.of_list(List.map((function (s) {
                    return renderSong(s, currentlyPlaying, send);
                  }), songList));
}

function addFeedbackToSong(content, loc, song) {
  var c = /* record */Block.record([
      "location",
      "content"
    ], [
      loc,
      content
    ]);
  var compareComments = function (c1, c2) {
    return Caml_primitive.caml_float_compare(c1[/* location */0], c2[/* location */0]);
  };
  return /* record */Block.record([
            "id",
            "title",
            "artist",
            "url",
            "feedback"
          ], [
            song[/* id */0],
            song[/* title */1],
            song[/* artist */2],
            song[/* url */3],
            List.sort(compareComments, Belt_List.add(song[/* feedback */4], c))
          ]);
}

var exampleSong = /* record */Block.record([
    "id",
    "title",
    "artist",
    "url",
    "feedback"
  ], [
    1,
    "Young and Beautiful Love",
    "Ashley D'Souza",
    "https://soundcloud.com/ashley-dsouza-106423765/young-and-beautiful-love",
    Block.simpleVariant("::", [
        /* record */Block.record([
            "location",
            "content"
          ], [
            6.3,
            "Nice gentle piano intro!"
          ]),
        /* :: */Block.simpleVariant("::", [
            /* record */Block.record([
                "location",
                "content"
              ], [
                20.1,
                "Lovely, breathy entrance!"
              ]),
            /* :: */Block.simpleVariant("::", [
                /* record */Block.record([
                    "location",
                    "content"
                  ], [
                    48.0,
                    "Make that first -- \"I\" told my love -- more clear"
                  ]),
                /* [] */0
              ])
          ])
      ])
  ]);

var demoSongHeader = renderSongHeader(exampleSong, Util.ignore, "");

var demoRenderSongNotCurrent = renderSong(exampleSong, undefined, Util.ignore);

var songCurrentlyPlaying = /* record */Block.record([
    "song",
    "position",
    "enteredText"
  ], [
    exampleSong,
    13.293,
    "Editing comment ..."
  ]);

var demoRenderSongCurrent = renderSong(exampleSong, songCurrentlyPlaying, Util.ignore);

var initialSongs_001 = /* :: */Block.simpleVariant("::", [
    /* record */Block.record([
        "id",
        "title",
        "artist",
        "url",
        "feedback"
      ], [
        3,
        "Sunday Morning",
        "Maroon 5",
        "https://soundcloud.com/maroon-5/sunday-morning",
        0
      ]),
    /* :: */Block.simpleVariant("::", [
        /* record */Block.record([
            "id",
            "title",
            "artist",
            "url",
            "feedback"
          ], [
            4,
            "Payphone",
            "Maroon 5",
            "https://soundcloud.com/maroon-5/payphone-clean",
            0
          ]),
        /* [] */0
      ])
  ]);

var initialSongs = /* :: */Block.simpleVariant("::", [
    exampleSong,
    initialSongs_001
  ]);

var demoRenderSongList = renderSongList(initialSongs, songCurrentlyPlaying, Util.ignore);

var Demo = /* module */Block.localModule([
    "exampleSong",
    "demoSongHeader",
    "demoRenderSongNotCurrent",
    "songCurrentlyPlaying",
    "demoRenderSongCurrent",
    "initialSongs",
    "demoRenderSongList"
  ], [
    exampleSong,
    demoSongHeader,
    demoRenderSongNotCurrent,
    songCurrentlyPlaying,
    demoRenderSongCurrent,
    initialSongs,
    demoRenderSongList
  ]);

var Player = 0;

exports.Player = Player;
exports.renderSongHeader = renderSongHeader;
exports.renderCommentsRoll = renderCommentsRoll;
exports.renderPlayerOnCurrentSong = renderPlayerOnCurrentSong;
exports.renderSong = renderSong;
exports.renderSongList = renderSongList;
exports.addFeedbackToSong = addFeedbackToSong;
exports.Demo = Demo;
/*  Not a pure module */
