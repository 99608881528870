'use strict';

var List = require("bs-platform/lib/js/list.js");
var Song = require("./Song.bs.js");
var Util = require("./Util.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var React = require("react");
var Belt_Debug = require("bs-platform/lib/js/belt_Debug.js");
var ReasonReact = require("reason-react/src/ReasonReact.js");

Belt_Debug.setupChromeDebugger(/* () */0);

var component = ReasonReact.reducerComponent("App");

function make(initialSongs, _) {
  return /* record */Block.record([
            "debugName",
            "reactClassInternal",
            "handedOffState",
            "willReceiveProps",
            "didMount",
            "didUpdate",
            "willUnmount",
            "willUpdate",
            "shouldUpdate",
            "render",
            "initialState",
            "retainedProps",
            "reducer",
            "jsElementWrapped"
          ], [
            component[/* debugName */0],
            component[/* reactClassInternal */1],
            component[/* handedOffState */2],
            component[/* willReceiveProps */3],
            component[/* didMount */4],
            component[/* didUpdate */5],
            component[/* willUnmount */6],
            component[/* willUpdate */7],
            component[/* shouldUpdate */8],
            (function (self) {
                return React.createElement("div", {
                            className: "app"
                          }, React.createElement("div", {
                                className: "app-header"
                              }, React.createElement("div", {
                                    className: "app-title"
                                  }, Util.str("Lentil")), React.createElement("div", {
                                    className: "app-subtitle"
                                  }, Util.str("Musicians Helping Musicians"))), React.createElement("div", {
                                className: "app-menu"
                              }, ReasonReact.element(undefined, undefined, Util.Text[/* make */1]("MENU", undefined, /* array */[])), ReasonReact.element(undefined, undefined, Util.Text[/* make */1]("Perform", undefined, /* array */[])), ReasonReact.element(undefined, undefined, Util.Text[/* make */1]("Review", "bold", /* array */[])), ReasonReact.element(undefined, undefined, Util.Text[/* make */1]("Improve", undefined, /* array */[])), ReasonReact.element(undefined, undefined, Util.Text[/* make */1]("Settings", undefined, /* array */[]))), React.createElement("div", {
                                className: "app-content"
                              }, Song.renderSongList(self[/* state */1][/* songList */0], self[/* state */1][/* playing */1], self[/* send */3])));
              }),
            (function () {
                return /* record */Block.record([
                          "songList",
                          "playing"
                        ], [
                          initialSongs,
                          undefined
                        ]);
              }),
            component[/* retainedProps */11],
            (function (action, state) {
                if (typeof action === "number") {
                  var match = state[/* playing */1];
                  if (match !== undefined) {
                    var match$1 = match;
                    var p = match$1[/* position */1];
                    var s = match$1[/* song */0];
                    var updatedSong = Song.addFeedbackToSong(match$1[/* enteredText */2], p, s);
                    var updatedState_000 = /* songList */List.map((function (s$prime) {
                            var match = s$prime[/* id */0] === s[/* id */0];
                            if (match) {
                              return updatedSong;
                            } else {
                              return s$prime;
                            }
                          }), state[/* songList */0]);
                    var updatedState_001 = /* playing *//* record */Block.record([
                        "song",
                        "position",
                        "enteredText"
                      ], [
                        updatedSong,
                        p,
                        ""
                      ]);
                    var updatedState = /* record */Block.record([
                        "songList",
                        "playing"
                      ], [
                        updatedState_000,
                        updatedState_001
                      ]);
                    return /* Update */Block.variant("Update", 0, [updatedState]);
                  } else {
                    console.log("ERROR: LEAVE COMMENT WHILE NO SONG PLAYING");
                    return /* NoUpdate */0;
                  }
                } else {
                  switch (action.tag | 0) {
                    case 0 : 
                        return /* Update */Block.variant("Update", 0, [/* record */Block.record([
                                      "songList",
                                      "playing"
                                    ], [
                                      state[/* songList */0],
                                      /* record */Block.record([
                                          "song",
                                          "position",
                                          "enteredText"
                                        ], [
                                          action[0],
                                          0.0,
                                          ""
                                        ])
                                    ])]);
                    case 1 : 
                        var match$2 = state[/* playing */1];
                        if (match$2 !== undefined) {
                          var match$3 = match$2;
                          return /* Update */Block.variant("Update", 0, [/* record */Block.record([
                                        "songList",
                                        "playing"
                                      ], [
                                        state[/* songList */0],
                                        /* record */Block.record([
                                            "song",
                                            "position",
                                            "enteredText"
                                          ], [
                                            match$3[/* song */0],
                                            action[0],
                                            match$3[/* enteredText */2]
                                          ])
                                      ])]);
                        } else {
                          console.log("ERROR: UPDATE PROGRESS WHILE NO SONG PLAYING");
                          return /* NoUpdate */0;
                        }
                    case 2 : 
                        var match$4 = state[/* playing */1];
                        if (match$4 !== undefined) {
                          var match$5 = match$4;
                          return /* Update */Block.variant("Update", 0, [/* record */Block.record([
                                        "songList",
                                        "playing"
                                      ], [
                                        state[/* songList */0],
                                        /* record */Block.record([
                                            "song",
                                            "position",
                                            "enteredText"
                                          ], [
                                            match$5[/* song */0],
                                            match$5[/* position */1],
                                            action[0]
                                          ])
                                      ])]);
                        } else {
                          console.log("ERROR: ENTER TEXT WHEN NO SONG PLAYING");
                          return /* NoUpdate */0;
                        }
                    
                  }
                }
              }),
            component[/* jsElementWrapped */13]
          ]);
}

var demoApp = ReasonReact.element(undefined, undefined, make(Song.Demo[/* initialSongs */5], /* array */[]));

var Demo = /* module */Block.localModule(["demoApp"], [demoApp]);

exports.component = component;
exports.make = make;
exports.Demo = Demo;
/*  Not a pure module */
